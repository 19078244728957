import { FunctionComponent, useEffect, useMemo, useState } from "react";
import {
  InsightsQueryData,
  QueryWidgetSelection,
} from "./InsightsWidgetSelectionDialog";
import { DashboardModel, FilterDimension } from "./types";
import { useGlobalSnackbar } from "../../consecutive-snackbar/GlobalSnackbar";
import {
  Filter,
  filters,
  createDimension,
  createAttribute,
} from "@sisense/sdk-data";
import { ExecuteQueryByWidgetId } from "@sisense/sdk-ui";

/** @todo Support more filter types as Sisense expands CSDK filtering */
const getFilterType = (type: string): string => {
  switch (type) {
    case "numeric":
      return "numeric-attribute";
    case "text":
    default:
      return "text-attribute";
  }
};

export interface ExecuteMultiQueryByWidgetIdsProps {
  queryWidgetSelections: QueryWidgetSelection[];
  dashboardOid: string;
  selectedDashboard: string;
  sisenseEnv: string;
  appContext: any;
  confirmSelections: (queryData?: InsightsQueryData[]) => void;
}

export const ExecuteMultiQueryByWidgetIds: FunctionComponent<
  ExecuteMultiQueryByWidgetIdsProps
> = ({
  queryWidgetSelections,
  dashboardOid,
  sisenseEnv,
  appContext,
  selectedDashboard,
  confirmSelections,
}) => {
  const [queryData, setQueryData] = useState<InsightsQueryData[]>([]);
  const [widgetExecutedStates, setWidgetExecutedStates] = useState(
    new Map(
      queryWidgetSelections.map(
        (queryWidgetSelection: QueryWidgetSelection) => [
          queryWidgetSelection.widgetOid,
          false,
        ]
      )
    )
  );
  const [dashboard, setDashboard] = useState<DashboardModel | undefined>();
  const [isLoadingDashboard, setIsLoadingDashboard] = useState<boolean>();
  const [dashboardError, setDashboardError] = useState<Error | undefined>(
    undefined
  );

  const { simple } = useGlobalSnackbar();

  useEffect(() => {
    setIsLoadingDashboard(true);
    appContext.$$http
      .get(`${sisenseEnv}/api/v1/dashboards/${selectedDashboard}`)
      .then((response: any) => {
        setDashboard(response.data);
        setIsLoadingDashboard(false);
      })
      .catch((error: Error) => {
        setDashboardError(error);
        setIsLoadingDashboard(false);
      });
  }, []);

  const getColumnNameFromDim = (dim: string) => {
    const matches = dim.match(/\[(.*?)\.(.*?)\]/);
    return matches ? matches[2] : "";
  };

  const addFiltersToQuery = (filterJaqls: any[], qFilters: Filter[]) => {
    let columnName = "";
    filterJaqls.forEach((filterJaql: any) => {
      const dim = filterJaql.dim;
      const type = filterJaql.datatype;
      if (filterJaql.column) {
        columnName = filterJaql.column.replace(/\s/g, "");
      } else {
        columnName = getColumnNameFromDim(dim);
      }
      const filterDimension = createDimension({
        name: "Dimension",
        FilterDim: createAttribute({
          name: columnName,
          type: getFilterType(type),
          expression: dim,
        }),
      }) as FilterDimension;

      /*
       * If the filter's column name is "name", then the createAttribute function will make the name the value of the dimension.
       * E.g. "name" -> "dim_pintypes_name"
       * In order to correctly set the filter member, we have to get the filterDimension by the new name.
       */
      const filterDimensionName =
        columnName === "name"
          ? dim.replace(/\./g, "_").replace(/^\[|\]$/g, "")
          : columnName;
      const member = filters.members(
        filterDimension[filterDimensionName],
        filterJaql.filter.members
      );
      qFilters.push(member);
    });
  };

  const queryFilters = useMemo(() => {
    if (!dashboard) return [];
    if (dashboard.filters.length === 0) return [];

    const qFilters: Filter[] = [];
    dashboard.filters.forEach((filter: any) => {
      const filterJaqls = [];
      if (filter.jaql) {
        const jaql = filter.jaql;
        if (jaql.filter && jaql.filter.members) {
          filterJaqls.push(jaql);
        }
      } else if (filter.levels) {
        filter.levels.forEach((level: any) => {
          if (level.filter.members && level.filter.members.length) {
            filterJaqls.push(level);
          }
        });
      }
      if (filterJaqls.length) {
        addFiltersToQuery(filterJaqls, qFilters);
      }
    });

    return qFilters;
  }, [dashboard]);

  useEffect(() => {
    if (!dashboardError) return;
    simple(
      `Failed loading insights dashboard. Reason: ${dashboardError.message}`,
      { severity: "error" }
    );
  }, [dashboardError, simple]);

  useEffect(() => {
    if (!queryWidgetSelections.length) {
      confirmSelections();
    } else if (queryData.length === queryWidgetSelections.length) {
      confirmSelections(queryData);
    }
  }, [confirmSelections, queryData, queryWidgetSelections.length]);

  return (
    <div>
      {!isLoadingDashboard &&
        queryWidgetSelections.length &&
        queryWidgetSelections.map((w, index) => {
          if (!widgetExecutedStates.get(w.widgetOid)) {
            return (
              <ExecuteQueryByWidgetId
                key={index}
                widgetOid={w.widgetOid}
                dashboardOid={dashboardOid}
                filters={queryFilters}
                filtersMergeStrategy="codeFirst"
              >
                {(data: any) => {
                  if (data) {
                    const insightsQueryData: InsightsQueryData = {
                      widgetType: w.widgetType,
                      widgetTitle: w.widgetTitle,
                      widgetId: w.widgetOid,
                      columns: data.columns,
                      rows: data.rows,
                    };
                    setQueryData([...queryData, insightsQueryData]);
                    setWidgetExecutedStates(
                      widgetExecutedStates.set(w.widgetOid, true)
                    );
                    return null;
                  }
                  return null;
                }}
              </ExecuteQueryByWidgetId>
            );
          }

          return null;
        })}
    </div>
  );
};
